<template>
	<div>
		<div class="layout">
			<div class="header">
				<div class="hd"><h2 class="wow showb" data-wow-delay="0.2s" data-wow-duration="4s">POWERED BY BITCOIN</h2></div>
			</div>
			<div class="banner">
				<h1 class="wow showb" data-wow-delay="0.2s" data-wow-duration="5s">Rune Game Console</h1>
				<div class="pic wow showbda2" data-wow-delay="0.2s" data-wow-duration="3s"><img src="@/assets/images/a.gif" alt=""></div>
				<h3 class="wow showb" data-wow-delay="0.2s" data-wow-duration="8s">
					The first <span>10000</span> different rune game consoles, <br />
					deciphering the secrets contained in the runes <br />
					will result in mysterious airdrops <br />
				</h3>
			</div>
			<div class="page1">
				<h2 class="wow showbda" data-wow-delay="0.2s" data-wow-duration="3s">The treasure of rune game consoles. </h2>
				<div class="list">
					<ul>
						<li>
							<div class="pic wow showbda2" data-wow-delay="0.2s" data-wow-duration="3s"><img src="@/assets/images/fh5.png" alt=""></div>
							<h3 class="wow showbda2" data-wow-delay="0.2s" data-wow-duration="3s">Game</h3>
							<div class="jia wow showbda2" data-wow-delay="0.2s" data-wow-duration="3s"></div>
						</li>
						<li>
							<div class="pic wow showbda2" data-wow-delay="0.2s" data-wow-duration="4s"><img src="@/assets/images/fh6.png" alt=""></div>
							<h3 class="wow showbda2" data-wow-delay="0.2s" data-wow-duration="3s">Runes</h3>
							<div class="next wow showbda2" data-wow-delay="0.2s" data-wow-duration="4s"></div>
						</li>
						<li>
							<div class="pic wow showbda2" data-wow-delay="0.2s" data-wow-duration="4s"><img src="@/assets/images/fh9.png" alt=""></div>
							<h3 class="wow showbda2" data-wow-delay="0.2s" data-wow-duration="3s">RuneGame</h3>
						</li>
					</ul>
				</div>
			</div>
			<div class="page2">
				<div class="lts">
					<div class="picr wow showbda3" data-wow-delay="0.2s" data-wow-duration="4s"><img src="@/assets/images/fh11.png" alt=""></div>
				</div>
				<div class="text wow showbda4" data-wow-delay="0.2s" data-wow-duration="4s">
					TAfter obtaining the rune <br />
					game console, you shoul<br />
					d actively come to our <br />
					Twitter account to <br />
					answer the secrets contained <br />
					in the rune. If you<br />
					guess correctly, you can unlock <br />
					the <span>rune treasure</span>. <br />
					You can't say anything more<br />
				</div>
			</div>
			<div class="footer">
				<div class="hds">
					<div class="game wow showb" data-wow-delay="0.2s" data-wow-duration="4s">game</div>
					<div class="copyright wow showb" data-wow-delay="0.2s" data-wow-duration="5s"><router-link to="/" >@ 2024 RUNE GAME. BY <span>BITCOIN</span> MAXIS</router-link></div>
					<div class="like" @click="doX"><img src="@/assets/images/fh1.png" alt=""></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {
		    
		  },
		data() {
			return {
				
			}
		},
		mounted() {
		    this.$nextTick(() => {
		        new this.$wow.WOW().init()
		      })
		},
		methods: {
			doX(){
        window.open("https://twitter.com/RuneGameConsole", "_blank");

      }
		}

	}
</script>

<style>
.showb {
  -webkit-animation-name: showb;
  animation-name: showb;
}

@keyframes showb {
  0% {
	  clip-path: inset(-60% 100% -60% -10%);
	  animation-timing-function: cubic-bezier(0.31, 0.92, 0.6, 1.01);
  }
  
  	  100% {
  		  clip-path: inset(-60% -10% -60% -10%);
	}
}





</style>

