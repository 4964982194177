import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import '@/assets/css/slicy.css'
import '@/assets/css/style.css'
import '@/assets/css/responsive.css'
import 'wowjs/css/libs/animate.css'
import wow from 'wowjs'
import 'wowjs/css/libs/animate.css'

Vue.config.productionTip = false

Vue.prototype.$wow = wow // 将 wowjs 挂载到 Vue 原型上

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
